$(document).ready(function() {

    //Menü-Icon animation auslösen
    $('.menu-icon').click(function () {
        $(this).toggleClass('open');
        $('.nav0-1').slideToggle('fast');
    });

    // Prüfen ob Untermenüpunkte existieren
    var submenuitem = $('nav ul li');
    submenuitem.find('div').prev('a').append('<span class="dropdown-icon visible-xs visible-sm"><img alt="Dropdown-Icon" src="img/angle-down.svg"></span>');

    if ($(window).width() < 992 ) {
        //Submenüebenen (de)aktivieren
        $('nav ul li a').click(function () {
            submenuitem.find('div.active').prev('a').find('.dropdown-icon').removeClass('rotate');
            var sub = $(this).next('div');
            if (sub) {
                $(sub).toggleClass('active');
            }
            submenuitem.find('div.active').prev('a').find('.dropdown-icon').addClass('rotate');
        });
    }

    $(window).resize(function() {
        if ($(window).width() < 992 ) {
            //Submenüebenen (de)aktivieren
            $('nav ul li a').click(function () {
                submenuitem.find('div.active').prev('a').find('.dropdown-icon').removeClass('rotate');
                var sub = $(this).next('div');
                if (sub) {
                    $(sub).toggleClass('active');
                }
                submenuitem.find('div.active').prev('a').find('.dropdown-icon').addClass('rotate');
            });
        } else {
            $('nav ul li a').unbind('click');
        }
    });

    $('.scroll2top').click(function() {
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
    });

    $(function() {
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 500);
                    return false;
                }
            }
        });
    });

    if($('.slider').length != 0) {
        $('.slider').slick({
            slidesToShow: 1,
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000
        });
    }

    if($('.angebotsslider').length != 0) {
        $('.angebotsslider').slick({
            slidesToShow: 1,
            dots: true,
            arrows: true,
            autoplay: false,
            autoplaySpeed: 3000
        });
    }

    $(".fancybox").fancybox();

});
